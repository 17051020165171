// Lib dependencies
import { useLocation } from 'react-router-dom';
import React from 'react';

// Providers
import BrandProvider from './providers/BrandProvider';
import I18nProvider from './i18n/providers/I18nProvider';
import EntityProvider from './providers/EntityProvider';
import MantineProvider from './mantine/providers/MantineProvider';
import ModalsProvider from './mantine/providers/ModalsProvider';
import GlobalStylesProvider from './mantine/providers/GlobalStylesProvider';

// Layout
import DynamicAppMetadata from './shared/layout/DynamicAppMetadata';

// Router
import Router from './router';
import routesPaths from './router/configs/routesPaths.configs';

const App = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  return (
    <MantineProvider>
      <ModalsProvider>
        <GlobalStylesProvider />
        {pathname !== routesPaths.notFound ? (
          <BrandProvider>
            <DynamicAppMetadata>
              <I18nProvider>
                <EntityProvider>
                  <Router />
                </EntityProvider>
              </I18nProvider>
            </DynamicAppMetadata>
          </BrandProvider>
        ) : (
          <Router />
        )}
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;
